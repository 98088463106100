import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { debounce } from 'lodash';
import axios from 'axios';
import imageService from '../../../services/imageService';
import Category from '../../Projects/Images/Category';
import Modal from '../../Projects/Images/Modal';
import ImageUploader from '../../Projects/Images/ImageUploader';
import api from '../../../utils/api';
import { Spinner } from 'flowbite-react';
import { PhotoIcon } from '@heroicons/react/24/outline';

const ProjectImagesPage = () => {
  const [categories, setCategories] = useState({
    Uncategorized: [],
    Inspiration: [],
    'Work in Progress': [],
    Completed: [],
  });

  const [showModal, setShowModal] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const { projectId } = useParams();
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    async function fetchImages() {
      try {
        const images = await imageService.getImages(projectId);
        const categorizedImages = {
          Uncategorized: [],
          Inspiration: [],
          'Work in Progress': [],
          Completed: [],
        };

        images.forEach((image) => {
          const category = image.category || 'Uncategorized';
          categorizedImages[category].push(image);
        });

        setCategories(categorizedImages);
      } catch (error) {
        console.error('Failed to fetch images:', error);
      }
    }

    fetchImages();
  }, [projectId]);

  const handleImageClick = (image) => {
    setCurrentImage(image);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setCurrentImage(null);
    setShowModal(false);
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (!file) return;

      const formData = new FormData();
      formData.append('image', file);

      setIsUploading(true);
      try {
        const newImage = await imageService.uploadImage(projectId, formData);
        setCategories((prevCategories) => ({
          ...prevCategories,
          Uncategorized: [...prevCategories.Uncategorized, newImage],
        }));
        window.location.reload();
      } catch (error) {
        console.error('Failed to upload image:', error);
        // Add error handling here, e.g., show an error message to the user
      } finally {
        setIsUploading(false);
      }
    },
    [projectId]
  );

  const handleDeleteImage = async (imageId) => {
    try {
      await imageService.deleteImage(imageId);
      setCategories((prevCategories) => {
        const updatedCategories = { ...prevCategories };
        for (const key in updatedCategories) {
          updatedCategories[key] = updatedCategories[key].filter((image) => image._id !== imageId);
        }
        return updatedCategories;
      });
    } catch (error) {
      console.error(`Failed to delete image with id: ${imageId}`, error);
    }
  };

  const handleDropImage = async (imageId, newCategory) => {
    let image;
    let oldCategory;

    setCategories((prevCategories) => {
      const updatedCategories = { ...prevCategories };

      for (const key in updatedCategories) {
        const foundImage = updatedCategories[key].find((img) => img._id === imageId);
        if (foundImage) {
          image = foundImage;
          oldCategory = key;
          break;
        }
      }

      if (!image || oldCategory === newCategory) return prevCategories;

      updatedCategories[oldCategory] = updatedCategories[oldCategory].filter((img) => img._id !== imageId);

      updatedCategories[newCategory].push(image);

      return updatedCategories;
    });

    try {
      await imageService.updateImageCategory(projectId, imageId, newCategory);
    } catch (error) {
      console.error('Failed to update image category:', error);
    }
  };

  const debouncedUpdateOrder = useCallback(
    debounce(async (projectId, imageId, newOrder) => {
      try {
        await api.put(`${process.env.REACT_APP_SERVER_URL}/api/images/images/${projectId}/${imageId}/category-order`, { order: newOrder });
      } catch (error) {
        console.error('Failed to update image order:', error);
      }
    }, 500),
    []
  );

  const moveImage = (fromIndex, toIndex, fromCategory, toCategory) => {
    setCategories((prevCategories) => {
      const updatedCategories = { ...prevCategories };
      const movedImage = updatedCategories[fromCategory].splice(fromIndex, 1)[0];
      updatedCategories[toCategory].splice(toIndex, 0, movedImage);

      if (fromCategory === toCategory) {
        updatedCategories[toCategory].forEach((image, index) => {
          image.order = index;
          debouncedUpdateOrder(projectId, image._id, index);
        });
      } else {
        updatedCategories[fromCategory].forEach((image, index) => {
          image.order = index;
          debouncedUpdateOrder(projectId, image._id, index);
        });
        updatedCategories[toCategory].forEach((image, index) => {
          image.order = index;
          debouncedUpdateOrder(projectId, image._id, index);
        });
      }

      return updatedCategories;
    });
  };

  const handleSelectImage = async (imageId) => {
    try {
      await api.put(`${process.env.REACT_APP_SERVER_URL}/api/images/images/${imageId}`, {
        projectId,
        selected: true,
      });
      setCategories((prevCategories) => {
        const updatedCategories = { ...prevCategories };
        for (const key in updatedCategories) {
          updatedCategories[key] = updatedCategories[key].map((image) => ({
            ...image,
            selected: image._id === imageId,
          }));
        }
        return updatedCategories;
      });
    } catch (error) {
      console.error('Failed to select image:', error);
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="min-h-screen bg-gray-50 py-8">
        {showModal && <Modal currentImage={currentImage} handleCloseModal={handleCloseModal} />}

        <div className="max-w-[95%] mx-auto">
          <div className="bg-white rounded-2xl shadow-sm overflow-hidden">
            <div className="px-10 py-8 border-b border-gray-100">
              <div className="flex items-center justify-between">
                <div className="space-y-1.5">
                  <h1 className="text-2xl font-semibold text-gray-800 tracking-tight">Project Images</h1>
                  <p className="text-sm text-gray-500">Organize and manage your project images by category</p>
                </div>
                <div className="flex items-center space-x-4">
                  {isUploading && (
                    <div className="flex items-center space-x-2 bg-gray-50 px-4 py-2 rounded-full">
                      <Spinner size="sm" />
                      <span className="text-sm text-gray-600 font-medium">Uploading...</span>
                    </div>
                  )}
                </div>
              </div>

              <div className="mt-8">
                <ImageUploader
                  onDrop={onDrop}
                  isDragActive={false}
                  className="w-full border-2 border-dashed border-gray-200 rounded-xl p-10 transition-all duration-200 hover:border-[#2A9D8F] hover:bg-gray-50"
                />
              </div>
            </div>

            <div className="px-10 py-8">
              <div className="grid grid-cols-1 gap-8">
                {Object.entries(categories).map(([category, images]) => (
                  <div key={category} className="bg-gray-50 rounded-xl p-8 transition-all duration-200 hover:shadow-md hover:bg-gray-100/50 w-full">
                    <Category
                      name={category}
                      images={images}
                      onDropImage={handleDropImage}
                      onImageClick={handleImageClick}
                      onDeleteImage={handleDeleteImage}
                      onSelectImage={handleSelectImage}
                      moveImage={moveImage}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </DndProvider>
  );
};

export default ProjectImagesPage;
