import axios from 'axios';
import api from '../utils/api';

const API_URL = process.env.REACT_APP_SERVER_URL;

export const imageService = {
  getImages: async (projectId) => {
    try {
      const response = await api.get(`${API_URL}/api/images/project/${projectId}/images`);
      return response.data;
    } catch (error) {
      console.error('Error fetching images:', error.message);
      throw error;
    }
  },

  uploadImage: async (projectId, formData) => {
    try {
      const response = await api.post(`${API_URL}/api/images/images/${projectId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error uploading image:', error.message);
      throw error;
    }
  },

  deleteImage: async (imageId) => {
    try {
      await api.delete(`${API_URL}/api/images/images/${imageId}`);
    } catch (error) {
      console.error('Error deleting image:', error.message);
      throw error;
    }
  },

  updateImageCategory: async (projectId, imageId, newCategory) => {
    try {
      await api.put(`${API_URL}/api/images/images/${projectId}/${imageId}/category-order`, { category: newCategory });
    } catch (error) {
      console.error('Error updating image category:', error.message);
      throw error;
    }
  },

  downloadImage: async (imageId, filename) => {
    try {
      // Make a request to our download endpoint
      const response = await api.get(`${API_URL}/api/images/images/${imageId}/download`, {
        responseType: 'blob', // Important for handling the file download
      });


      // Create a blob URL and trigger download
      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      
      // Get filename from Content-Disposition header if available
      const contentDisposition = response.headers['content-disposition'];
      const imageFilename = filename ? filename : "download.png";
      
      link.setAttribute('download', imageFilename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading image:', error);
      throw error;
    }
  },
};

export default imageService;

export const { getImages, uploadImage, deleteImage, updateImageCategory, downloadImage } = imageService;