import React from 'react';
import { useDrop } from 'react-dnd';
import Image from './Image';
import { PhotoIcon } from '@heroicons/react/24/outline';

const ItemType = {
  IMAGE: 'image',
};

const Category = ({ name, images, onDropImage, onImageClick, onDeleteImage, onSelectImage, moveImage }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: ItemType.IMAGE,
    drop: (item) => onDropImage(item.id, name),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  return (
    <div className="bg-white rounded-2xl shadow-sm overflow-hidden transition-all duration-300 hover:shadow-md">
      <div className="px-8 py-5 border-b border-gray-100">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <h3 className="text-lg font-medium text-gray-900 tracking-tight">{name}</h3>
            <div className="h-4 w-[1px] bg-gray-200" />
            <span className="inline-flex items-center px-2 py-0.5 text-xs font-medium text-gray-500">
              {images.length} {images.length === 1 ? 'image' : 'images'}
            </span>
          </div>
        </div>
      </div>

      <div
        ref={drop}
        className={`
          relative p-8 transition-all duration-300 ease-in-out
          ${isOver ? 'bg-[#2A9D8F]/[0.03]' : 'bg-white'}
        `}
      >
        {images.length === 0 ? (
          <div
            className={`
              flex flex-col items-center justify-center py-16 rounded-xl
              transition-all duration-300 ease-in-out
              ${isOver ? 'bg-[#2A9D8F]/[0.03] border-[#2A9D8F]/20' : 'bg-gray-50/50 border-gray-100 hover:bg-gray-50/80'}
              border-2 border-dashed
            `}
          >
            <PhotoIcon className="h-10 w-10 text-gray-400/80" />
            <p className="mt-3 text-sm font-medium text-gray-500">Drop images here</p>
            <p className="mt-1 text-xs text-gray-400">Drag and drop to add to {name.toLowerCase()}</p>
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
            {images.map((image, index) => (
              <Image
                key={image._id}
                image={image}
                index={index}
                category={name}
                onImageClick={() => onImageClick(image)}
                onDeleteImage={onDeleteImage}
                onSelectImage={onSelectImage}
                moveImage={moveImage}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Category;
