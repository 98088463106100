import React, { useState } from 'react';
import { ArrowDownTrayIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { imageService } from '../../../services/imageService';

const Modal = ({ currentImage, handleCloseModal }) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownload = async () => {
    try {
      setIsDownloading(true);
      await imageService.downloadImage(currentImage._id, currentImage.filename);
    } catch (error) {
      console.error('Failed to download image:', error);
      // You might want to add a toast notification here for error handling
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <div className="fixed z-50 top-0 left-0 w-full h-full bg-black/80 backdrop-blur-sm flex items-center justify-center transition-opacity duration-300">
      <div className="relative w-full h-full flex items-center justify-center p-4">
        {/* Close button */}
        <button
          onClick={handleCloseModal}
          className="absolute top-4 right-4 text-white/70 hover:text-white focus:outline-none transition-colors duration-200 z-10"
        >
          <XMarkIcon className="w-8 h-8" />
        </button>

        {/* Download button */}
        <button
          onClick={handleDownload}
          disabled={isDownloading}
          className={`absolute top-4 right-16 text-white/70 hover:text-white focus:outline-none transition-colors duration-200 z-10 flex items-center gap-2 ${
            isDownloading ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          <ArrowDownTrayIcon className={`w-7 h-7 ${isDownloading ? 'animate-bounce' : ''}`} />
        </button>

        {/* Image container with subtle border and shadow */}
        <div className="relative max-w-7xl max-h-[90vh] bg-white rounded-lg overflow-hidden shadow-2xl">
          <img src={currentImage.signedUrl} alt="Full screen view" className="w-full h-full object-contain" />

          {/* Optional: Image filename display */}
          <div className="absolute bottom-0 left-0 right-0 bg-black/50 text-white px-4 py-2 text-sm backdrop-blur-sm">{currentImage.filename}</div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
